
import { Options, Vue } from "vue-class-component";
import CaseHeader from "@/components/case/CaseHeader.vue";
import Workflow from "@/components/case/Workflow.vue";
import TitleTextBlock from "@/components/ui/TitleTextBlock.vue";
import Team from "@/components/ui/Team.vue";
import Results from "@/components/case/Results.vue";
import  cases  from "@/assets/data/cases.js";


@Options({
  components: {
    CaseHeader,
    Workflow,
    TitleTextBlock,
    Team,
    Results,
  },
  prop:{
    cases
  }
})
export default class CaseDetail extends Vue {
  data: any   = {};
 
  next() {
    const nextId = this.data.id + 1;

    if(cases.findIndex(c=>c.id === nextId)!=-1){
  
       window.location.href = '/case-detail/' + nextId; // use window.location insted of router because to force reloading of the page
    }else{
       window.location.href = '/case-detail/1';
    }  

  }
  back(){
      this.$router.push('/cases');
  }
  mounted(){
    const  id =  +this.$router.currentRoute.value.params.id;
    
    if(cases.length>0){
    this.data  = cases.find(c=>c.id === id);

    if(!this.data){
      this.data = cases[0];
    } 
    }else{
     this.$router.push('/cases');
    }

  }
}
