<template>
<div class="full-width workflow-wrap">
<div class="content">
  <div class="top-gap-padding">
    <div class="left-gap">
     <title-text-block
          v-bind:data="{ title:'Этапы работы' }"
        ></title-text-block>
        </div>
    <div class="workflow tablet-hide"  v-if="items && items.length!=0">
      <div
        class="item-wrap flex flex-justify-end"
        v-bind:class="[item.border]"
        v-for="item in items"
        :key="item.number"
      >
        <div class="flex">
          <div class="line"></div>
          <div class="item">
            <div class="item-desc">
              <div class="h4-text item-desc_title">
                {{ item.title }}
              </div>
              <div class="secondary-text item-desc_text">
                {{ item.text }}
              </div>
            </div>

            <div class="item_number h4-text desktop">0{{ item.number }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="workflow-mobile tablet-show" v-if="items && items.length!=0">
      <div class="item-wrap-mobile" v-for="(item, index) in itemsMobile" :key="item.number" v-bind:class="{first:index == 0,last: index == items.length - 1, middle: index!=0 && index!=items.length - 1 }">
        <div class="item_number h4-text mobile">0{{ item.number }}</div>
        <div class="item-desc">
          <div class="h4-text item-desc_title">
            {{ item.title }}
          </div>
          <div class="secondary-text item-desc_text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
   
  </div>
  </div>
   <img
      class="workflow-bg"
      src="~@/assets/img/backgrounds/workflow-background.svg"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TitleTextBlock from "@/components/ui/TitleTextBlock.vue";

class WorkflowData {
  text!: string;
  title!: string;
  number!: number;
  border!: number; // right, left
}

@Options({
  props: {
    items: { type: Object as () => WorkflowData },
  },
  components:{
    TitleTextBlock
  }
})
@Options({
  components: {},
})
export default class Workflow extends Vue {
  items:WorkflowData[] = [];

  get itemsMobile() {
    const temp = [...this.items];
    return temp.sort((a, b) => (a.number > b.number) ? 1 : -1)
  }
}
</script>

<style scoped lang="scss">
.workflow-wrap {
  position: relative;
}

.workflow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  z-index: 200;
}

.line {
  width: 1px;
  height: 39px;
  border-left: 2px solid $light-blue;
  margin-right: -3px;
  z-index: 300;
}

.item {
  border-left: 1px solid #e6e6e6;
  height: 130px;
  width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item-desc {
  margin: 10px;
}

.item-desc_title {
  margin-bottom: 8px;
}

.item-desc_text {
  color: #626670;
}

.item_number {
  border-radius: 50%;
  border: 1px solid $light-blue;
  background-color: #e5f2ff;
  width: 38px;
  min-width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $light-blue;
  z-index: 400;

  &.desktop {
    margin-bottom: -20px;
    margin-left: -1px;
  }

  &.mobile {
    margin-left: -20px;
  }
}

.item-wrap {
  border-bottom: 2px dashed #97b4f4;
  height: 230px;
  align-items: flex-end;
  justify-content: center;

  &.right {
    border-right: 2px dashed #97b4f4;
  }

  &.left {
    border-left: 2px dashed #97b4f4;
  }
}

.workflow-bg {
  position: absolute;
  top: 10%;
  z-index: 100;
  width: 100%;
}

.workflow-mobile{
  margin: 40px;
  z-index: 300;
  position: relative;
  display: none;
}

.item-wrap-mobile{
  border-left: 2px dashed #97b4f4;
  display: flex;
  align-items: baseline;
  
    &.middle{
      padding: 24px 0;
    }

    &.first{
      padding-bottom: 24px;
    }

    &.last{
      border-left: none;
      margin-bottom: 24px;
    }
}

@media screen and (max-width: 730px) {
.workflow-bg {
  top: 40%;
}
  .tablet-show{
      display: block;
  }
  .tablet-hide{
      display: none;
  }
}


@media screen and (max-width: 450px) {
.workflow-bg {
  top: 50%;
}
}
</style>