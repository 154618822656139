
import { Options, Vue } from "vue-class-component";
import TitleTextBlock from "@/components/ui/TitleTextBlock.vue";

class WorkflowData {
  text!: string;
  title!: string;
  number!: number;
  border!: number; // right, left
}

@Options({
  props: {
    items: { type: Object as () => WorkflowData },
  },
  components:{
    TitleTextBlock
  }
})
@Options({
  components: {},
})
export default class Workflow extends Vue {
  items:WorkflowData[] = [];

  get itemsMobile() {
    const temp = [...this.items];
    return temp.sort((a, b) => (a.number > b.number) ? 1 : -1)
  }
}
