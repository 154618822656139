
import { Options, Vue } from "vue-class-component";
import TitleTextBlock from "@/components/ui/TitleTextBlock.vue";

class ResultData {
  text!: string;
  number!: number;
}

@Options({
  components: {
    TitleTextBlock,
  },
  props: {
    title: String,
    text: String,
    items: { type: Array<ResultData>() },
  },
})
export default class Results extends Vue {
  titleAlign = "center";
}
