
import { Options, Vue } from "vue-class-component";

 export class CaseDetailHeaderData{
      title!: string;
      alias!: string;
      logoWidth!:number;
      description!: string;
      customer!:string;
      headerFontSize!:string;
 }

@Options({
  props: {
    data: {type:  Object as () => CaseDetailHeaderData}
  }
})
export default class CaseHeader extends Vue {
}
