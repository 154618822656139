<template>
  <div class="header flex flex-align-center flex-justify-center">
    <img
      class="header-bg"
      src="~@/assets/img/backgrounds/case-header-background.svg"
    />
    <div class="header-wrap">
      <div class="header-back">
        <div
          class="flex flex-align-center flex-justify-start cursor"
          @click="$emit('back', $event)"
        >
          <img class="back-arrow" src="~@/assets/icons/arrow-back.svg" alt="" />
          <div class="h4-text">Все бизнес кейсы</div>
        </div>
      </div>
      <div class="header-info flex flex-wrap flex-justify-center flex-align-center">
        <div
          class="header-text flex flex-dir-col flex-align-baseline flex-justify-start"
        >
          <div class="header-text_title h1-text" v-bind:style="{fontSize: data.fontSize}">{{data.title}}</div>
          <div class="header-text_subtitle">
           {{data.description}}
          </div>
        </div>
        <div class="case flex flex-align-baseline flex-dir-col">
          <div class="case-info flex flex-justify-start">
            <div class="case-info_text secondary-text">
              Заказчик: {{data.customer}}
            </div>
            <img v-if="data.alias"
              class="case-info_img"
              :src="require(`@/assets/img/companies/${data.alias}.svg`)"
              v-bind:style="{width: data.logoWidth + 'px'}"
              alt=""
            />
          </div>
          <img class="dashboard-img"
            :src="require(`@/assets/img/cases/${data.alias}-dashboard.png`)"
            alt=""
            style="max-width: 850px;"
          />

          <div class="case-info-tablet">
             <div class="case-info_text secondary-text">
              Заказчик: {{data.customer}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

 export class CaseDetailHeaderData{
      title!: string;
      alias!: string;
      logoWidth!:number;
      description!: string;
      customer!:string;
      headerFontSize!:string;
 }

@Options({
  props: {
    data: {type:  Object as () => CaseDetailHeaderData}
  }
})
export default class CaseHeader extends Vue {
}
</script>

<style scoped lang="scss">
.header-bg {
  position: absolute;
  z-index: 200;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 165%;
  min-height: 100%;
  top: 50%;
  left: 49%;
  transform: translate(-49%, -50%);
  margin-top: -150px;
}
.header {
  color: $white;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.header-text {
  z-index: 300;
  position: relative;
  max-width: 565px;
  margin: 16px 30px;

  .header-text_title {
    margin-bottom: 24px;
  }

  .header-text_subtitle {
    margin-bottom: 80px;
  }
}
.case,
.header-back {
  position: relative;
  z-index: 300;
  margin-bottom: 10px;
}

.header-wrap {
  width: 100%;
  max-width: 1500px;
  padding: 20px;
}

.case-info_img {
    object-fit: contain;
}

.case-info_text {
  margin: 16px 16px 16px 0;
}

.header-info {
  width: 100%;
  max-width: 1500px;
}

.back-arrow{
    margin-right: 8px;
}

.dashboard-img{
  width: 100%;
  box-shadow: 10px 10px 10px rgba(13, 76, 216, 0.1);
}

.case-info-tablet{
 display: none;
 }

@media screen and (max-width: 1294px) {
  .header-text_subtitle {
    margin-bottom: 35px !important;
  }

  .header-text{
    margin: 0;
  }

  .case-info-tablet{
    display: block;
    color: $font-color;
  }

  .case-info{
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .header-text_title {
    word-break: break-word;
  }
}
</style>
