<template>
  <div class="result-wrap white-text top-gap-margin">
    <div class="content">
      <div class="title">
        <title-text-block
          v-bind:data="{ title, text, align: titleAlign }"
        ></title-text-block>
      </div>
      <div class="results flex flex-wrap flex-justify-space-around">
        <div
          class="result-item flex flex-dir-col"
          v-for="item in items"
          :key="item.number"
        >
          <div class="result-item_number">0{{ item.number }}</div>
          <div class="result-item_back-number">0{{ item.number }}</div>
          <div class="result-item_text">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TitleTextBlock from "@/components/ui/TitleTextBlock.vue";

class ResultData {
  text!: string;
  number!: number;
}

@Options({
  components: {
    TitleTextBlock,
  },
  props: {
    title: String,
    text: String,
    items: { type: Array<ResultData>() },
  },
})
export default class Results extends Vue {
  titleAlign = "center";
}
</script>

<style scoped lang="scss">
.result-item {
  position: relative;
  max-width: 235px;
}

.result-item_text {
  margin-top: 32px;
}

.result-item_number {
  font-size: 64px;
  font-weight: 800;
  z-index: 300;
}

.result-item_back-number {
  font-size: 144px;
  font-weight: 800;
  z-index: 200;
  font-feature-settings: "pnum" on, "lnum" on;
  opacity: 0.05;
  position: absolute;
  top: -76px;
  left: 25px;
}

.result-wrap {
  background: linear-gradient(180deg, #040084 0%, #0141cf 100%);
  padding: 72px;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  width:  stretch;
  z-index: 200;
}

.title {
  max-width: 620px;
  margin: 0 auto;
  margin-bottom: 65px;
}

@media screen and (max-width: 1000px) {
.result-wrap{
    padding: 50px;
}
}

@media screen and (max-width: 730px) {
.result-wrap{
    padding: 20px;
}
}

@media screen and (max-width: 844px) {
    .results{
        justify-content: end;
    }
}


</style>