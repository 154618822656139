<template>
  <case-header v-on:back="back()"
   v-bind:data="{ title: data?.headerTitle, description: data?.description, alias: data?.alias, customer: data?.customer, logoWidth: data?.logoWidth, fontSize: data?.fontSize}"
  ></case-header>

    <div class="setting full-width">
      <div class="setting_up-line line"></div>
         <div class="content">
      <div class="setting_info left-gap top-gap-margin">
        <title-text-block
          v-bind:data="{ title: data?.problemTitle, text: data?.problemText, align: titleAlign }"
        ></title-text-block>
      </div>
         </div>
      <div class="setting_bottom-line line"></div>
    </div>


    <workflow v-bind:items ="data?.workflowItems"></workflow>

  <results
    v-bind:items="data?.results"
    v-bind:title="data?.resultsTitle"
    v-bind:text="data?.resultsText"
  ></results>
<div class="full-width">
  <div class="content">
    <div class="left-gap">
      <div class="team-title h2-text margin-top-gap-margin">
        Команда
        <!-- <title-text-block
          v-bind:data="{ title:'Команда' }"
        ></title-text-block> -->
        </div>
      <div class="line team-line"></div>
    
      <team v-bind:items="data?.team"></team>
      
    </div>
    <div class="flex flex-justify-end">
      <div class="next flex flex-align-center" @click="next()">
        <div class="h4-text">Следующий кейс</div>
        <img class="arrow" src="~@/assets/icons/arrow-next.svg" alt="" />
      </div>
    </div>
  </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CaseHeader from "@/components/case/CaseHeader.vue";
import Workflow from "@/components/case/Workflow.vue";
import TitleTextBlock from "@/components/ui/TitleTextBlock.vue";
import Team from "@/components/ui/Team.vue";
import Results from "@/components/case/Results.vue";
import  cases  from "@/assets/data/cases.js";


@Options({
  components: {
    CaseHeader,
    Workflow,
    TitleTextBlock,
    Team,
    Results,
  },
  prop:{
    cases
  }
})
export default class CaseDetail extends Vue {
  data: any   = {};
 
  next() {
    const nextId = this.data.id + 1;

    if(cases.findIndex(c=>c.id === nextId)!=-1){
  
       window.location.href = '/case-detail/' + nextId; // use window.location insted of router because to force reloading of the page
    }else{
       window.location.href = '/case-detail/1';
    }  

  }
  back(){
      this.$router.push('/cases');
  }
  mounted(){
    const  id =  +this.$router.currentRoute.value.params.id;
    
    if(cases.length>0){
    this.data  = cases.find(c=>c.id === id);

    if(!this.data){
      this.data = cases[0];
    } 
    }else{
     this.$router.push('/cases');
    }

  }
}
</script>

<style scoped lang="scss">
.line {
  border: 2px solid $light-blue;
}
.setting_up-line {
  width: 30%;
}

.setting_bottom-line {
  width: 60%;
  float: right;
}

.setting_info {
  max-width: 545px;
}

.team-line {
  width: 110px;
  border: 2px solid $blue;
  margin-bottom: 40px;
}

.team-title {
  margin-bottom: 10px;
}

.next {
  color: $blue;
  width: max-content;
  cursor: pointer;

  .arrow {
    margin-left: 8px;
  }
}
</style>